import './bootstrap';
// Remove or install rellax if you need it
// import './rellax';

// Make sure this package is installed
import '@fortawesome/fontawesome-pro/css/all.css';
import Alpine from 'alpinejs';

// Initialize Alpine
window.Alpine = Alpine;
Alpine.start();

document.addEventListener('DOMContentLoaded', function() {
    console.log('DOM Content Loaded');

    const nav = document.querySelector('nav');
    console.log('Nav element:', nav); // Check if nav is found

    const navLinks = nav.querySelectorAll('.nav-link:not(.bg-amber-400)');
    console.log('Nav links:', navLinks); // Check if links are found

    const logoText = nav.querySelector('.logo-gradient');

    // Check if we're on a comparison page
    const isComparisonPage = window.location.pathname.includes('/compare/');

    // Set initial state
    if (isComparisonPage) {
        navLinks.forEach(link => {
            link.classList.add('text-white', 'hover:text-amber-200');
            link.classList.remove('text-gray-700', 'hover:text-blue-600');
        });
        logoText.classList.add('bg-gradient-to-bl', 'from-white', 'to-amber-400/60');
    } else if (window.scrollY <= 50) {
        navLinks.forEach(link => {
            link.classList.add('text-white', 'hover:text-amber-200');
        });
        logoText.classList.add('bg-gradient-to-bl', 'from-white', 'to-amber-400/60');
    }

    window.addEventListener('scroll', function() {
        if (isComparisonPage) return;

        console.log('Scrolling, Y position:', window.scrollY); // Check scroll position

        if (window.scrollY > 50) {
            nav.classList.remove('bg-transparent');
            nav.classList.add('bg-white', 'shadow-lg');

            navLinks.forEach(link => {
                link.classList.remove('text-white', 'hover:text-amber-200');
                link.classList.add('text-gray-700', 'hover:text-blue-600');
            });

            // Match the ShurWorks gradient
            logoText.classList.remove('bg-gradient-to-bl', 'from-white', 'to-amber-400/60');
            logoText.classList.add('bg-gradient-to-r', 'from-gray-600', 'to-gray-400');
        } else {
            nav.classList.add('bg-transparent');
            nav.classList.remove('bg-white', 'shadow-lg');

            navLinks.forEach(link => {
                link.classList.add('text-white', 'hover:text-amber-200');
                link.classList.remove('text-gray-700', 'hover:text-blue-600');
            });

            // Light gradient for transparent background
            logoText.classList.remove('bg-gradient-to-r', 'from-gray-600', 'to-gray-400');
            logoText.classList.add('bg-gradient-to-bl', 'from-white', 'to-amber-400/60');
        }
    });
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const targetId = this.getAttribute('href');
        const targetElement = document.querySelector(targetId);

        if (targetElement) {
            const headerOffset = 0;
            const elementPosition = targetElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    });
});
